// Filename - ContactUs.jsx
import React from "react";
 
const ContactUs = () => {
    return (
        <div>
            <h1>Contact Us Page</h1>
        </div>
    );
};
 
export default ContactUs;